<template>
  <div>
    <!--内容-->
    <div class="page-content-x">
      <el-row>
        <el-col :span="12"
          ><h3>{{ page_name }}</h3></el-col
        >
      </el-row>
      <div style="height: 20px"></div>
      <!--列表-->
      <el-table
        size="medium"
        border
        v-loading="loading"
        :data="tableData"
        style="width: 100%"
      >
        <el-table-column prop="title" label="课程名称" width="200">
          <template slot-scope="scope">
            <span>{{ scope.row.lesson_name }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="store_name" label="门店名称" width="200">
          <template slot-scope="scope">
            <span
              >【{{ scope.row.store_company_name }}】{{ scope.row.store_name }}—{{
                scope.row.classroom_name
              }}</span
            >
          </template>
        </el-table-column>
        <el-table-column prop="user_nick" label="教练昵称" width="200">
          <template slot-scope="scope">
            <el-avatar shape="square" :size="22" :src="scope.row.portrait"></el-avatar>
            <span
              >{{ scope.row.coach_nick }}（{{ scope.row.coach_name }}:{{
                scope.row.coach_phone
              }}）</span
            >
          </template>
        </el-table-column>
        <el-table-column prop="user_name" label="用户昵称" width="200">
          <template slot-scope="scope">
            <el-avatar shape="square" :size="22" :src="scope.row.avatar_url"></el-avatar>
            <span>{{ scope.row.user_nick }}（{{ scope.row.phone }}）</span>
          </template>
        </el-table-column>
        <el-table-column prop="create_time" label="创建时间" width="180">
        </el-table-column>
        <el-table-column prop="order_no" label="订单号" width="200"> </el-table-column>

        <el-table-column prop="title" label="标题" width="200"> </el-table-column>
        <el-table-column prop="lesson_type" label="课程类型" width="80">
          <template slot-scope="scope">
            <el-tag size="mini" v-if="scope.row.lesson_type === 1">团课</el-tag>
            <el-tag size="mini" v-if="scope.row.lesson_type === 2" type="success"
              >训练营
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="lesson_duration" label="上课时间" width="210">
          <template slot-scope="scope">
            <el-popover placement="right" width="200" trigger="click">
              <el-table :data="scope.row.lesson_time">
                <el-table-column width="200" property="date" label="上课时间">
                  <template slot-scope="scope2">
                    {{ scope2.row }}
                  </template>
                </el-table-column>
              </el-table>
              <el-button type="text" size="mini" slot="reference"
                >{{ scope.row.start_time }}开始，共{{ scope.row.lesson_sum }}节
              </el-button>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="price" label="价格" width="100">
          <template slot-scope="scope">
            <span>￥{{ scope.row.price }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="qty" label="数量" width="100">
          <template slot-scope="scope">
            <span>{{ scope.row.qty }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="qty" label="总价" width="100">
          <template slot-scope="scope">
            <span>￥{{ scope.row.total_money }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="qty" label="优惠金额" width="100">
          <template slot-scope="scope">
            <span>￥{{ scope.row.coupon_money }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="qty" label="支付金额" width="100">
          <template slot-scope="scope">
            <span>￥{{ scope.row.pay_money }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="pay_type" label="支付方式/支付状态/支付单号" width="300">
          <template slot-scope="scope">
            <span v-if="scope.row.pay_type === 'BALANCE'">余额支付</span>
            <span v-if="scope.row.pay_type === 'WXPAY'">微信支付</span>
            <span v-if="scope.row.pay_type === 'ALIPAY'">支付宝</span>
            <span v-if="scope.row.pay_type === 'CARD'">商家卡</span>
            <span v-if="scope.row.pay_type === 'SCORE'">积分支付</span>
            <span v-if="scope.row.pay_type === 'CONSUMER_CARD'">消费卡</span>
            /
            <el-tag size="mini" v-if="scope.row.pay_state === 1">已支付</el-tag>
            <el-tag size="mini" v-if="scope.row.pay_state === 0" type="warning"
              >未支付
            </el-tag>
            /{{ scope.row.pay_order_no }}
          </template>
        </el-table-column>
        <el-table-column prop="pay_time" label="支付时间" width="150">
          <template slot-scope="scope">
            <span v-if="scope.row.pay_time !== '1970-01-01 08:00:00'">{{
              scope.row.pay_time
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="state" label="订单状态" width="80">
          <template slot-scope="scope">
            <el-tag size="mini" v-if="scope.row.state === 1">正常</el-tag>
            <el-tag size="mini" v-if="scope.row.state === 2" type="warning"
              >排队中</el-tag
            >
            <el-tag size="mini" v-if="scope.row.state === 5" type="danger">取消</el-tag>
            <el-tag size="mini" v-if="scope.row.state === -1" type="danger">删除</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="is_queue_up"
          label="退款状态/退款金额/退款单号"
          width="300"
        >
          <template slot-scope="scope">
            <el-tag size="mini" v-if="scope.row.refund_state === 1" type="warning"
              >有退款
            </el-tag>
            <span v-if="scope.row.refund_state === 1">
              /￥{{ scope.row.refund_money }} /{{ scope.row.refund_order_no }}
            </span>
            <el-tag size="mini" v-if="scope.row.refund_state === 0">无</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="is_queue_up" label="退款时间/退款备注" width="300">
          <template slot-scope="scope">
            <span v-if="scope.row.refund_time !== '1970-01-01 08:00:00'"
              >{{ scope.row.refund_time }}/</span
            >
            <span>{{ scope.row.refund_remarks }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="update_time" label="更新时间" width="180">
        </el-table-column>
        <!-- <el-table-column fixed="right" label="操作" width="130">
          <template slot-scope="scope">
            <div
              v-if="
                (scope.row.state === 1 || scope.row.state === 2) &&
                scope.row.pay_state === 1
              "
            >
              <el-button
                v-if="is_auth('order.group.orderrefund')"
                @click="openRefund(scope.row.order_no)"
                size="mini"
                type="danger"
                >退款
              </el-button>
            </div>
            <div v-else>
              <el-button
                disabled
                v-if="is_auth('order.group.orderrefund')"
                size="mini"
                plain
                type="info"
                >退款
              </el-button>
            </div>
          </template>
        </el-table-column> -->
      </el-table>
      <div style="height: 20px"></div>
      <!--分页-->
      <el-pagination
        @current-change="getlist"
        :page-size="this.env.pageSize"
        :pager-count="7"
        background
        layout="prev, pager, next, total"
        :current-page.sync="page"
        :total="count"
      >
      </el-pagination>
      <!--退款备注-->
      <el-dialog
        title="退款"
        :visible.sync="isRefundShow"
        width="50%"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
      >
        <el-row>
          <el-form size="medium" ref="form" label-width="120px">
            <el-col :span="24">
              <el-form-item label="退款备注">
                <el-input
                  type="textarea"
                  :rows="2"
                  placeholder="请输入内容"
                  :maxlength="300"
                  v-model="refund_remarks"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-form>
        </el-row>
        <el-row style="margin: 10px 0px">
          <el-button
            v-if="is_auth('order.group.orderrefund')"
            @click="refund('event')"
            size="medium"
            type="success"
            >退款
          </el-button>
          <el-button @click="refund('close')" size="medium" type="danger"
            >取消
          </el-button>
        </el-row>
      </el-dialog>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      page_name: "团课订单",
      loading: true, // 加载状态
      issearch: false, // 搜索是否展示
      isRefundShow: false,
      search: {}, // 搜索内容
      count: 0, // 数据总条数
      page: 1, // 当前页数
      tableData: [], // 列表内容
      searchtime: null, // 时间条件
      refund_remarks: "",
    };
  },
  // 创建
  created() {
    // this.init();
  },
  // 安装
  mounted() {},
  methods: {
    // 权限判断
    is_auth(auth) {
      return this.Tool.is_auth(auth);
    },
    // 初始化
    init(tag, id) {
      this.user_uuid = id;
      this.tag = tag;
      this.search = {
        tag_s: tag,
      };
      // this.searchtime = null;
      this.is_search();
    },
    //搜索
    is_search() {
      this.page = 1;
      this.getlist();
    },
    // 获取列表
    getlist() {
      let postdata = {
        api_name: "order.group.getlist",
        token: this.Tool.get_l_cache("token"),
        pagesize: this.env.pageSize,
        page: this.page,
        user_uuid: this.user_uuid,
      };
      // 非必须参数（搜索条件）
      Object.assign(postdata, this.search);
      this.loading = true;
      this.Tool.post_data("oss", postdata, (json) => {
        this.loading = false;
        if (json.code === 0) {
          this.tableData = json.data.list;
          this.count = json.data.count;
        } else {
          this.tableData = [];
          this.count = 0;
          this.Tool.errormes(json);
        }
      });
    },
    // 打开退款弹窗
    openRefund(order_no) {
      this.order_no = order_no;
      this.isRefundShow = true;
    },
    // 退款处理
    refund(type) {
      const { order_no, refund_remarks } = this;
      if (type === "event") {
        let postdata = {
          api_name: "order.group.orderrefund",
          token: this.Tool.get_l_cache("token"),
          order_no,
          refund_remarks,
          user_uuid: this.user_uuid,
        };
        this.Tool.post_data("oss", postdata, (json) => {
          if (json.code === 0) {
            this.$message({
              message: json.message,
              type: "success",
            });
            this.order_no = "";
            this.isRefundShow = false;
            this.refund_remarks = "";
            this.init();
          } else {
            this.Tool.errormes(json);
          }
        });
      } else {
        this.order_no = "";
        this.isRefundShow = false;
        this.refund_remarks = "";
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/*表格不换行*/
>>> .el-table__body .cell {
  white-space: nowrap;
  /*overflow: initial;*/
}

.form-tag_colour-div {
  min-height: 200px;
}
</style>
