<template>
  <div>
    <!--面包屑-->
    <div class="page-breadcrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>用户行为分析</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!--内容-->
    <div class="page-content-x">
      <!--搜索-->
      <div v-show="issearch" class="page-content-search">
        <el-form ref="form" :model="search" size="medium" :label-width="this.env.search_label_width">
          <el-row>
            <el-col :span="8">
              <el-form-item label="用户昵称">
                <el-input v-model="search.user_nick" />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="手机号">
                <el-input v-model="search.phone" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="用户行为">
                <el-select v-model="search.user_behavior" style="width: 50%">
                  <el-option label="未下单时间" value="no_order_time" />
                  <el-option label="未登录时间" value="no_login_time" />
                </el-select>
                <el-input style="width: 40%" v-model="search.user_behavior_num" type="number" />
                <span style="width: 10%; text-align: center; display: inline-block">天</span>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="下单情况">
                <el-select v-model="search.order_placement" style="width: 50%">
                  <el-option label="全部" value="all" />
                  <el-option v-for="(item, key) in lesson_tag_list" :key="key" :label="item.name"
                    :value="item.name">
                  </el-option>
                </el-select>
                <span style="width: 5%; text-align: center; display: inline-block">></span>
                <el-input style="width: 35%" v-model="search.order_placement_num" type="number" />
                <span style="width: 10%; text-align: center; display: inline-block">单</span>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="品类标签">
                <el-select v-model="search.user_tag_new">
                  <el-option label="全部" value="all" />
                  <el-option v-for="(item, key) in lesson_tag_list" :key="key" :label="item.name"
                    :value="item.name">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="订单总金额 ≥">
                <el-input style="width: 90%" v-model="search.total_order_amount" type="number"></el-input>
                <span style="width: 10%; text-align: center; display: inline-block">元</span>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="总订单数 ≥">
                <el-input style="width: 90%" v-model="search.total_order_num" type="number"></el-input>
                <span style="width: 10%; text-align: center; display: inline-block">单</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="page-content-search-button">
            <el-col :span="24">
              <el-form-item>
                <el-button icon="el-icon-search" size="medium" type="primary" @click="is_search">搜索
                </el-button>
                <el-button icon="el-icon-circle-close" size="medium" @click="init">清空搜索</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <!--标题-->
      <el-row>
        <el-col :span="12">
          <h3>{{ page_name }}</h3>
        </el-col>
        <el-col :span="12">
          <div class="top-operation-button">
            <el-button v-if="issearch" icon="el-icon-caret-top" size="medium" type="primary"
              @click="issearch = !issearch">隐藏搜索
            </el-button>
            <el-button v-else icon="el-icon-caret-bottom" size="medium" type="primary" @click="issearch = !issearch">
              显示搜索
            </el-button>
            <el-button v-if="is_auth('user.recall.batchasyncsendmsg')" size="medium" type="primary"
              @click="showSendModel(null, 'sms')">批量短信提醒
            </el-button>
            <el-button v-if="is_auth('user.recall.batchasyncsendmsg')" size="medium" type="primary"
              @click="showSendModel(null, 'wx')">批量小程序消息
            </el-button>
            <el-button v-if="is_auth('user.recall.batchasyncsendmsg')" size="medium" type="primary"
              @click="showSendModel(null, 'coupon')">批量发优惠券
            </el-button>
            <el-button v-if="is_auth('user.recall.getlist')" icon="el-icon-download" size="medium" type="primary"
              @click="exportNote">导出
            </el-button>
          </div>
        </el-col>
      </el-row>
      <div style="height: 20px"></div>
      <!--列表-->
      <el-table size="medium" border v-loading="loading" :data="tableData" style="width: 100%">
        <el-table-column prop="user_uuid" label="用户uid" width="80" show-overflow-tooltip />
        <el-table-column prop="user_nick" label="昵称" width="120" show-overflow-tooltip />
        <el-table-column prop="phone" label="手机号" width="120" show-overflow-tooltip />
        <el-table-column prop="phone" label="下单情况" width="120" show-overflow-tooltip>
          <template slot-scope="scope">
            <div v-if="scope.row.order_tag_name_list.length > 0">
              <div v-for="(item, index) in scope.row.order_tag_name_list" :key="index">
                <div style="margin-bottom: 5px">
                  <el-tag>{{ item.tag_name }}</el-tag>
                  <el-link type="primary" style="margin-left: 5px" @click="openOrder(item.tag_name, scope.row)">{{
                      item.tag_name_num
                  }}次</el-link>
                </div>
              </div>
            </div>
            <p style="text-align: center" v-else>暂无订单</p>
          </template>
        </el-table-column>
        <el-table-column prop="phone" label="品类标签" width="120" show-overflow-tooltip>
          <template slot-scope="scope">
            <div v-if="scope.row.new_tag_name">
              <div v-for="(item, index) in scope.row.new_tag_name" :key="index">
                <div style="margin-bottom: 5px">
                  <el-tag>{{ item }}</el-tag>
                </div>
              </div>
            </div>
            <p style="text-align: center" v-else>暂无标签</p>
          </template>
        </el-table-column>
        <el-table-column prop="last_login_time" label="最近登录时间" width="180" show-overflow-tooltip />
        <el-table-column prop="last_order_time" label="最近下单时间" width="180" show-overflow-tooltip />
        <el-table-column prop="total_order_num" label="总订单数" width="100" show-overflow-tooltip />
        <el-table-column prop="total_order_money" label="订单总金额" width="100" show-overflow-tooltip />
        <el-table-column fixed="right" label="操作" width="420">
          <template slot-scope="scope">
            <el-button v-if="is_auth('user.member.getinfo') && scope.row.phone !== ''" @click="goToDetail(scope.row)"
              size="mini">查看
            </el-button>
            <el-button v-if="is_auth('user.member.givecoupon')" size="mini" type="primary"
              @click="showSendModel(scope.row, 'coupon')">赠送优惠券
            </el-button>
            <el-button v-if="is_auth('user.recall.sendsmscode')" size="mini" type="primary"
              @click="showSendModel(scope.row, 'sms')">发送短信提醒
            </el-button>
            <el-button v-if="is_auth('user.recall.sendtemplate')" size="mini" type="primary"
              @click="showSendModel(scope.row, 'wx')">发送小程序提醒
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="height: 20px"></div>
      <!--分页-->
      <el-pagination @current-change="getlist" :page-size="this.env.pageSize" :pager-count="7" background
        layout="prev, pager, next, total" :current-page.sync="page" :total="count">
      </el-pagination>
      <!--编辑-->
      <el-dialog v-loading="loading" :title="
        sendType === 'wx'
          ? '请选择小程序消息模板'
          : sendType === 'coupon'
            ? '请选择要发放的优惠券'
            : sendType === 'sms'
              ? '请选择短信模板'
              : ''
      " :visible.sync="isSendShow" width="50%" :close-on-press-escape="false" :close-on-click-modal="false"
        @close="clearState">
        <el-row v-if="chooseUser.user_nick">
          <el-form size="medium" ref="form" :label-width="this.env.label_width">
            <el-col :span="24">
              <el-form-item style="font-weight: bold" label="用户名：">{{
                  chooseUser.user_nick
              }}</el-form-item>
            </el-col>
          </el-form>
        </el-row>
        <el-row>
          <el-form size="medium" ref="form" :label-width="this.env.label_width">
            <el-col :span="24">
              <el-form-item :label="sendType === 'coupon' ? '选择优惠券' : '选择模板'">
                <el-select v-model="name" style="width: 50%" :placeholder="sendType === 'wx'
            ? '请选择小程序消息模板'
            : sendType === 'coupon'
              ? '请选择要发放的优惠券'
              : sendType === 'sms'
                ? '请选择短信模板'
                : ''" filterable @change="changeTemplate">
                  <el-option v-for="item in templateList" :key="item.key_id" :label="item.name" :value="item.key_id" />
                </el-select>
              </el-form-item>
            </el-col>
          </el-form>
        </el-row>
        <el-row style="margin: 10px 0px">
          <el-button v-if="is_auth('user.recall.batchasyncsendmsg')" size="medium" type="primary" @click="sendTemplate">
            确 定</el-button>
          <el-button size="medium" @click="clearState">取 消</el-button>
        </el-row>
      </el-dialog>
      <el-dialog v-loading="loading" title="订单详情" :visible.sync="isOrderShow" width="80%" :close-on-press-escape="false"
        :close-on-click-modal="false" @close="closeOrderDialog">
        <!-- 订单查看 -->
        <OrderDialog ref="orderDialog" :is-order-show="isOrderShow" :choose-user="chooseUser" :choose-tag="chooseTag"
          :order-type="orderType" @changeOrderType="changeOrderType" />
      </el-dialog>
    </div>
  </div>
</template>
<script>
import OrderDialog from "./components/OrderDialog.vue";
export default {
  components: { OrderDialog },
  data() {
    return {
      page_name: "用户行为分析",
      loading: true, // 加载状态
      issearch: false, // 搜索是否展示
      search: {}, // 搜索内容
      chooseUser: {}, // 当前选中的用户信息
      name: "",
      count: 0, // 数据总条数
      page: 1, // 当前页数
      key_id: 0,
      tableData: [], // 列表内容
      lesson_tag_list: [],
      select_user_tag: [],
      select_lesson_tag: [],
      templateList: [],
      user_behavior: "no_order_time",
      sendType: "",
      chooseTag: "", // 当前选择的标签名
      orderType: "group", // 订单选择的类型
      isSendShow: false, // 发送模板是否显示
      isOrderShow: false, // 订单弹窗是否显示
      isBatch: false, // 是否是批量发送
    };
  },
  // 创建
  created() {
    this.init();
    this.get_lesson_tag();
  },
  methods: {
    // 判断是否为空
    is_empty(auth) {
      return this.Tool.is_empty(auth);
    },
    // 权限判断
    is_auth(auth) {
      return this.Tool.is_auth(auth);
    },
    // 初始化
    init() {
      this.search = {
        order_placement: "all",
        user_tag_new: "all",
      };
      this.searchtime = null;
      this.is_search();
    },
    //搜索
    is_search() {
      this.page = 1;
      this.getlist();
    },
    // 获取课程标签
    get_lesson_tag() {
      let postdata = {
        api_name: "user.recall.gettags",
        token: this.Tool.get_l_cache("token"),
      };
      this.loading = true;
      this.Tool.post_data("oss", postdata, (json) => {
        this.loading = false;
        if (json.code === 0) {
          this.lesson_tag_list = json.data.list;
        } else {
          this.lesson_tag_list = [];
          this.Tool.errormes(json);
        }
      });
    },
    // 获取列表
    getlist() {
      let postdata = {
        api_name: "user.recall.getlist",
        token: this.Tool.get_l_cache("token"),
        pagesize: this.env.pageSize,
        page: this.page,
      };
      // 非必须参数（搜索条件）
      Object.assign(postdata, this.search);
      this.loading = true;
      this.Tool.post_data("oss", postdata, (res) => {
        this.loading = false;
        if (res.code === 0) {
          this.tableData = res.data.list;
          this.count = res.data.total;
        } else {
          this.tableData = [];
          this.count = 0;
          this.Tool.errormes(res);
        }
      });
    },
    // 获取可用微信小程序/短信消息模板列表
    getWxSmsTemplateList() {
      const { sendType } = this;
      let postdata = {
        api_name:
          sendType === "wx"
            ? "usermsgtpl.wxtemplate.gettpllist"
            : sendType === "coupon"
              ? "coupon.library.getavailablelist"
              : sendType === "sms"
                ? "usermsgtpl.smstemplate.getsmslist"
                : "user.recall.getlist",
        token: this.Tool.get_l_cache("token"),
      };
      this.Tool.post_data("oss", postdata, (res) => {
        this.loading = false;
        if (res.code === 0) {
          this.templateList = res.data;
        } else {
          this.Tool.errormes(res);
        }
      });
    },
    showOrder() { },
    exportNote() {
      let postdata = {
        api_name: "user.recall.getlist",
        token: this.Tool.get_l_cache("token"),
        pagesize: this.env.pageSize,
        page: this.page,
        export: "yes",
      };
      // 非必须参数（搜索条件）
      Object.assign(postdata, this.search);
      this.loading = true;
      this.Tool.post_data("oss", postdata, (res) => {
        if (res.code === 10005) {
          this.Tool.errormes(res);
        } else {
          location.href = res;
        }
        this.loading = false;
      });
    },
    // 选择课程标签
    add_lesson_tag() { },
    goToDetail(item) {
      const { user_uuid } = item;
      this.$router.push({ path: "/user/member/info", query: { user_uuid: user_uuid } });
    },
    // 打开发送模板弹窗
    showSendModel(item, type) {
      if (item) {
        this.chooseUser = item;
        this.isBatch = false;
      } else {
        this.isBatch = true;
      }
      this.isSendShow = true;
      this.sendType = type;
      this.getWxSmsTemplateList();
    },
    // 选择可用模板
    changeTemplate(e) {
      this.key_id = e;
    },
    // 关闭弹窗清除数据
    clearState() {
      this.key_id = 0;
      this.isSendShow = false;
      this.chooseUser = {};
      this.sendType = "";
      this.templateList = [];
      this.name = "";
    },
    // 判断是批量发送还是单一发送
    sendTemplate() {
      const { isBatch } = this;
      if (isBatch) {
        if (this.count === 0) {
          this.$message.warning("当前未选中发送用户，请重新选择");
        } else {
          const h = this.$createElement;
          this.$msgbox({
            title: '发送提醒',
            message: h('p', null, [
              h('span', null, `是否确认给当前筛选的`),
              h('span', { style: 'color: red' }, ` ${this.count} `),
              h('span', null, `位用户发送信息，一经发送不可取消`),
            ]),
            showCancelButton: true,
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            beforeClose: (action, instance, done) => {
              if (action === 'confirm') {
                this.batchSend();
                done();
              } else {
                done();
              }
            }
          })
        }
      } else {
        this.send();
      }
    },
    // 发送模板或者优惠券
    send() {
      const { chooseUser, sendType, key_id } = this;
      const { user_uuid } = chooseUser;
      if (key_id === 0) {
        this.$message.warning("发送内容不可为空");
      } else {
        let postdata = {
          api_name:
            sendType === "wx"
              ? "user.recall.sendtemplate"
              : sendType === "coupon"
                ? "user.member.givecoupon"
                : sendType === "sms"
                  ? "user.recall.sendsmscode"
                  : "user.recall.sendsmscode",
          token: this.Tool.get_l_cache("token"),
        };
        if (sendType === "wx") {
          postdata["user_uuid"] = user_uuid;
          postdata["template_uuid"] = key_id;
        } else if (sendType === "coupon") {
          postdata["coupon_user_uuid"] = user_uuid;
          postdata["coupon_uuid"] = key_id;
        } else if (sendType === "sms") {
          postdata["user_uuid"] = user_uuid;
          postdata["sms_uuid"] = key_id;
        }
        this.Tool.post_data("oss", postdata, (res) => {
          if (res.code === 0) {
            this.$message.success("发送成功");
            this.clearState();
          } else {
            this.Tool.errormes(res);
          }
          this.loading = false;
        });
      }
    },
    // 批量发送模板信息
    batchSend() {
      const { sendType, key_id } = this;
      if (key_id === 0) {
        this.$message.warning("发送内容不可为空");
      } else {
        let postdata = {
          api_name: "user.recall.batchasyncsendmsg",
          token: this.Tool.get_l_cache("token"),
          channel_type:
            sendType === "wx"
              ? "template"
              : sendType === "sms"
                ? "sms_code"
                : sendType === "coupon"
                  ? "coupon"
                  : "",
          send_uuid: key_id,
        };
        // 非必须参数（搜索条件）
        Object.assign(postdata, this.search);
        this.Tool.post_data("oss", postdata, (res) => {
          if (res.code === 0) {
            this.$message.success("发送成功");
            this.clearState();
          } else {
            this.Tool.errormes(res);
          }
          this.loading = false;
        });

      }
    },
    // 打开用户订单
    openOrder(tag, item) {
      if (this.is_auth("order.group.getlist")) {
        this.isOrderShow = true;
        this.chooseUser = item;
        this.chooseTag = tag;
        setTimeout(() => {
          this.$refs.orderDialog.init(tag, item);
        }, 50);
      }
    },
    // 关闭用户订单
    closeOrderDialog() {
      this.isOrderShow = false;
      this.chooseUser = {};
      this.chooseTag = "";
      this.orderType = "group";
    },
    changeOrderType(type) {
      this.orderType = type;
    },
  },
};
</script>
