<template>
  <div class="page-content-x">
    <div style="display: flex; align-items: center; margin-bottom: 10px">
      <h3 style="margin-right: 10px">订单标签:</h3>
      <el-tag>{{ chooseTag }}</el-tag>
    </div>
    <!--标题-->
    <el-tabs :activeName="orderType" type="card" @tab-click="handleClick">
      <el-tab-pane label="团课" name="group">
        <GroupList :id="user_uuid" :tag="chooseTag" ref="group" />
      </el-tab-pane>
      <el-tab-pane label="私教" name="pt">
        <PtList :id="user_uuid" :tag="chooseTag" ref="pt" />
      </el-tab-pane>
      <el-tab-pane label="训练营" name="camp">
        <CampList :id="user_uuid" :tag="chooseTag" ref="camp" />
      </el-tab-pane>
      <el-tab-pane label="商品" name="commod">
        <CommodList :id="user_uuid" :tag="chooseTag" ref="commod" />
      </el-tab-pane>
      <el-tab-pane label="活动" name="activity">
        <ActivityList :id="user_uuid" :tag="chooseTag" ref="activity" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import GroupList from "./orderPage/groupList.vue";
import PtList from "./orderPage/ptList.vue";
import CampList from "./orderPage/campList.vue";
import CommodList from "./orderPage/commodList.vue";
import ActivityList from "./orderPage/activityList.vue";
export default {
  components: {
    GroupList,
    PtList,
    CampList,
    CommodList,
    ActivityList,
  },
  props: {
    orderType: {
      type: String,
      default: "group",
    },
  },
  data() {
    return {
      loading: false,
      user_uuid: "",
      chooseTag: "",
    };
  },
  methods: {
    init(tag, item) {
      this.chooseTag = tag;
      this.user_uuid = item.user_uuid;
      setTimeout(() => {
        this.$refs.group.init(tag, item.user_uuid);
      }, 50);
    },
    clearState() {
      this.$emit("closeOrderDialog");
    },
    handleClick(tab) {
      const { chooseTag, user_uuid } = this;
      this.$emit("changeOrderType", tab.paneName);
      if (tab.paneName === "pt") {
        this.$refs.pt.init(chooseTag, user_uuid);
      } else if (tab.paneName === "camp") {
        this.$refs.camp.init(chooseTag, user_uuid);
      } else if (tab.paneName === "commod") {
        this.$refs.commod.init(chooseTag, user_uuid);
      } else if (tab.paneName === "activity") {
        this.$refs.activity.init(chooseTag, user_uuid);
      } else if (tab.paneName === "group") {
        this.$refs.group.init(chooseTag, user_uuid);
      }
    },
  },
};
</script>
